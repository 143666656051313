module.exports = [{
      plugin: require('../plugins/gatsby-plugin-airgraft-theme/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"display":"minimal-ui","name":"Airgraft","short_name":"Airgraft","start_url":"/","background_color":"white","theme_color":"white","icon":"static/site-icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"8afdc9508b2098b0630b0c10603bcb27"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TF72QZK","gtmAuth":"zbzXF6vI7KJx-6HAGGX5zw","routeChangeEventName":"gatsby-route-change","includeInDevelopment":true,"defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ed88d50b0c4c47c0b9f6a6aa6094c021@sentry.io/1546841","release":"bb3fdf3988e737c0b75354ef99319cfac5aaa8cb","environment":"production"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
